import {
  LBTLabel,
  LBTSpacer,
  Section,
  LBTButton,
  LBTImage,
  COLORS,
  LBTAlert,
  LBTCard,
  CardVariant,
  LBTDivider,
  LBTDividerSize,
  LBTProgressSpinner,
} from '@laborability/components';
import {
  currentUserState,
  getAllQuestionFlowCallback,
  getUserEligibleMisureCallback,
  getUserProfileCallback,
  measuresLoaderState,
  measuresState,
  Misura,
  QUESTIONARY_STEPS,
  useLoading,
  useTrackAnalytics,
} from '@laborability/commons';
import { useEffect, useState } from 'react';
import { useRecoilCallback, useRecoilValue } from 'recoil';
import { useNavigate } from 'react-router-dom';
import { APP_ROUTES } from '../../Routes';
import InfiniteDiscover from '../../components/InfiniteDiscover';
import { Grid } from '@mui/material';

interface Props {
  step: number;
  setStep: (i: number) => void;
  setIsStepCompleted: (b: boolean) => void;
}

export default function QuestionaryLoader({
  step,
  setStep,
  setIsStepCompleted,
}: Props) {
  const navigate = useNavigate();
  const fetchMisure = useRecoilCallback(getUserEligibleMisureCallback, []);
  const measures = useRecoilValue(measuresState);
  const currentUser = useRecoilValue(currentUserState);
  const getFlows = useRecoilCallback(getAllQuestionFlowCallback, []);
  const getUserMe = useRecoilCallback(getUserProfileCallback, []);
  const measuresLoader = useRecoilValue(measuresLoaderState);
  const [hoverStates, setHoverStates] = useState<{ [key: number]: boolean }>(
    {},
  );
  const [track, setTrack] = useState(false);
  const isLoading = useLoading();
  const [trackAnalyticsProps, setTrackAnalyticsProps] = useState<
    {
      key: string;
      value: string;
    }[]
  >([]);

  const getMisure = async (skip?: number, limit?: number) => {
    const res = await fetchMisure({
      skip,
      limit,
    });
    if (!res || !res.data) {
      return [];
    }
    if (res.data) {
      setStep(0);
      return res.data;
    }
  };

  const isQuestionaryCompleted =
    currentUser.questionary_status?.length === QUESTIONARY_STEPS &&
    currentUser.questionary_status?.every(questionary => questionary.completed);

  useEffect(() => {
    if (!isQuestionaryCompleted) getMisure();
  }, []);

  useTrackAnalytics(!isLoading ? trackAnalyticsProps : []);

  const getTrackAnalyticsProps = () => {
    if (!track) {
      setTrack(true);
      return [
        { key: 'event', value: 'page_view' },
        { key: 'page_title', value: 'Questionario - Il grosso è fatto' },
        { key: 'page_location', value: '/onboarding/reward-screen/' },
      ];
    }

    return [];
  };

  useEffect(() => {
    setTrackAnalyticsProps(getTrackAnalyticsProps());
  }, [step]);

  if (isQuestionaryCompleted)
    return (
      <Section backgroundColor={COLORS.getInstance().PRIMARY_MAIN}>
        <LBTSpacer spacing={2} />
        <LBTImage image={'astronaut'} />
        <LBTSpacer spacing={2} />
        <LBTLabel variant="spGroteskSubtitle" component="h2">
          Incredibile ma vero
        </LBTLabel>
        <LBTSpacer spacing={2} />
        <LBTLabel variant="delaDisplay" component="h1">
          Hai sbloccato tutte le agevolazioni
        </LBTLabel>
        <LBTSpacer spacing={2} />
        <LBTLabel variant="bodyText">
          Grazie alle risposte che hai dato abbiamo selezionato le agevolazioni
          che ti spettano
        </LBTLabel>
        <LBTSpacer spacing={4} />
        <LBTButton
          datatestid="onboarding_button_sbloccato_tutte_le_agevolazioni"
          variant="contained"
          color="primary-negative"
          size="large"
          fullWidth
          sx={{ maxWidth: '504px' }}
          onClick={() => {
            setStep(0);
            setIsStepCompleted(false);
            navigate(`/${APP_ROUTES.MY_BONUS}`);
            getFlows();
            if (!step) getUserMe();
          }}
        >
          Vai alle tue agevolazioni
        </LBTButton>
        <LBTSpacer spacing={2} />
        <LBTAlert
          title="Ricorda!"
          color="info"
          variant="standard"
          message="Mantieni sempre aggiornate nel tempo le informazioni che riguardano te e i tuoi cari: le regole di accesso possono cambiare e ogni anno vengono pubblicati nuovi bonus. Puoi modificare le risposte dalla sezione Questionario raggiungibile dal menu in alto a destra"
          datatestid="alert-1"
          onClose={() => {}}
        />
        <LBTSpacer spacing={8} isFixed />
      </Section>
    );
  else if (step === 3) {
    return (
      <Section
        key={`section-${step}`}
        backgroundColor={COLORS.getInstance().PRIMARY_MAIN}
      >
        <LBTSpacer spacing={2} />
        <LBTImage image={'hooray'} />
        <LBTSpacer spacing={2} />
        <LBTLabel variant="sourceSubtitle" component="h2">
          A breve potrai vedere i primi risultati
        </LBTLabel>
        <LBTSpacer spacing={1} />
        <LBTLabel variant="delaDisplay" component="h1">
          E il grosso è fatto!
        </LBTLabel>
        <LBTSpacer spacing={2} />
        <LBTLabel variant="spGroteskSubtitle" component="h2">
          Le tue risposte ci hanno permesso di eliminare le agevolazioni a cui
          sicuramente non hai diritto
        </LBTLabel>
        <LBTSpacer spacing={4} />
        <LBTProgressSpinner color="neutral" variant="indeterminate" />
      </Section>
    );
  } else
    return (
      <Section
        key={`section-${step}`}
        sx={{ display: measuresLoader ? 'none' : 'flex' }}
      >
        <LBTSpacer spacing={2} />
        <LBTLabel variant="delaDisplay" component="h1">
          Hai accumulato {measures.length} bonus fin qui
        </LBTLabel>
        <LBTSpacer spacing={2} />
        <LBTImage image={'superhero'} />
        <LBTSpacer spacing={2} />
        <LBTLabel variant="bodyText">
          Salviamo le agevolazioni nella tua area personale: puoi consultarle in
          ogni momento dal menù in alto a destra, nella sezione Le tue
          agevolazioni
        </LBTLabel>
        <LBTSpacer spacing={4} />
        <LBTButton
          datatestid="onboarding_button_continua_a_esplorare"
          variant="contained"
          size="large"
          fullWidth
          sx={{ maxWidth: '504px' }}
          onClick={() => {
            setStep(0);
            setIsStepCompleted(false);
            navigate(`/${APP_ROUTES.QUESTIONARY}`);
            getFlows();
            if (!step) getUserMe();
          }}
        >
          Continua a esplorare
        </LBTButton>

        <LBTSpacer spacing={4} />
        <LBTDivider
          orientation="horizontal"
          width="100%"
          size={LBTDividerSize.SMALL}
          color={COLORS.getInstance().DIVIDER}
        />
        <LBTSpacer spacing={2} />

        <LBTLabel variant="sourceCaption" component="small">
          Agevolazioni a cui hai diritto:
        </LBTLabel>
        <InfiniteDiscover
          items={measures}
          lastSpacer={4}
          getMoreItems={async () => {
            return { items: measures, total: measures.length };
          }}
          renderItem={(item: Misura) => {
            const hasHover = item.id ? hoverStates[item.id] || false : false;
            return (
              <Grid item mobile={12} desktop={6} display="flex">
                <LBTCard
                  onClick={() =>
                    navigate(`/${APP_ROUTES.MEASURE_DETAIL}/${item.id}`)
                  }
                  title={item.name}
                  description={item.description}
                  tag={item.categories
                    ?.find(category =>
                      item.categories!.length === 2
                        ? !!category.parent_id
                        : category.parent_id === null,
                    )
                    ?.name?.toLowerCase()}
                  hasHover={hasHover}
                  onTouchStart={() => {
                    setHoverStates(prev => {
                      const newState = Object.keys(prev).reduce(
                        (acc: { [key: string]: boolean }, key: string) => {
                          acc[key] = false;
                          return acc;
                        },
                        {},
                      );
                      return {
                        ...newState,
                        [item.id!]: true,
                      };
                    });
                  }}
                  onTouchEnd={() => {
                    setHoverStates(prev => ({
                      ...prev,
                      [item.id!]: false,
                    }));
                  }}
                  onMouseEnter={() => {
                    setHoverStates(prev => ({
                      ...prev,
                      [item.id!]: true,
                    }));
                  }}
                  onMouseLeave={() => {
                    setHoverStates(prev => ({
                      ...prev,
                      [item.id!]: false,
                    }));
                  }}
                  news={item.news ?? false}
                  expired={item.expired ?? false}
                  expiringSoon={item.expiring_soon ?? false}
                  upcoming={item.upcoming ?? false}
                  cardVariant={CardVariant.CONTAINED}
                />
              </Grid>
            );
          }}
        />
      </Section>
    );
}
