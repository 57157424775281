import {
  checkQuestionaryAccess,
  currentUserState,
  snackBarState,
  TEST_DOMAINS,
  useKeycloak,
  useStartup,
} from '@laborability/commons';
import md5 from 'md5';
import { useEffect, useState } from 'react';
import TagManager from 'react-gtm-module';
import { Outlet } from 'react-router-dom';
import { initializeTagManager } from '../utils/gtm';
import { KeycloakContext } from '@laborability/components';
import { currentCompanyConfigState } from '@laborability/commons';
import Loader from './Loader';
import { useRecoilValue } from 'recoil';

export default function Auth() {
  const { keycloak, init } = useKeycloak();
  const { onStartup, isCompletedStartup } = useStartup();

  const currentCompany = useRecoilValue(currentCompanyConfigState);
  const currentUser = useRecoilValue(currentUserState);

  const currentUrl = new URL(window.location.href);
  const currentUrlSearchParams = currentUrl.searchParams;
  const [isInactive, setIsInactive] = useState(false);
  const [lastInteraction, setLastInteraction] = useState(Date.now());
  const inactivityLimit = 30 * 60 * 1000; // 30 minutes
  const firstLogin = sessionStorage.getItem('login');
  const domain = currentUser.email?.substring(
    currentUser.email?.indexOf('@') + 1,
  );
  if (!currentUrlSearchParams.has('gtm_debug')) {
    const gtmDebug = sessionStorage.getItem('gtm_debug');
    if (gtmDebug) {
      const newUrl = new URL(window.location.href);
      newUrl.searchParams.append('gtm_debug', gtmDebug);
      window.history.pushState({}, '', newUrl);
    }
  } else {
    const gtmDebug = currentUrlSearchParams.get('gtm_debug');
    if (gtmDebug) sessionStorage.setItem('gtm_debug', gtmDebug);
  }

  const { code } = useRecoilValue(snackBarState);

  const handleActivity = () => {
    const now = Date.now();
    if (isInactive) window.location.reload();

    setLastInteraction(now);
    setIsInactive(false);
  };

  const checkInactivity = () => {
    const now = Date.now();
    const timeSinceLastInteraction = now - lastInteraction;
    if (timeSinceLastInteraction > inactivityLimit) setIsInactive(true);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleActivity);
    window.addEventListener('click', handleActivity);
    const interval = setInterval(checkInactivity, 60000);

    return () => {
      window.removeEventListener('scroll', handleActivity);
      window.removeEventListener('click', handleActivity);
      clearInterval(interval);
    };
  }, [isInactive, lastInteraction]);

  useEffect(() => {
    if (code && code === 401) keycloak?.logout();
  }, [code]);

  useEffect(() => {
    // initializeTagManager();
    init();
  }, []);

  useEffect(() => {
    if (keycloak?.tokenParsed) {
      onStartup();
    }
  }, [keycloak?.tokenParsed]);

  useEffect(() => {
    if (keycloak?.tokenParsed) {
      if (currentUser && firstLogin !== 'true') {
        sessionStorage.setItem('login', 'true');
        const dataLayer = {
          event: 'login',
          id_user: md5(keycloak.tokenParsed.preferred_username),
          company:
            currentUser.companies && currentUser.companies.length > 0
              ? `company-${currentUser.companies[0].id}`
              : 'admin',
          company_name:
            currentUser.companies && currentUser.companies.length > 0
              ? currentUser.companies[0].name
              : 'debug',
          timestamp: new Date().toISOString(),
          is_prod_env: process.env.REACT_APP_ENV === 'prod' ? 'true' : 'false',
          page_title: 'Auth',
          page_location: '/app/auth',
          user_test: domain
            ? TEST_DOMAINS.includes(domain)
              ? 'true'
              : 'false'
            : null,
        };

        window.dataLayer.push(dataLayer);
        console.log('@@@tagManager', dataLayer);
      }
    }
  }, [currentUser, firstLogin]);

  return (
    <KeycloakContext.Provider
      value={{
        keycloak,
        hasQuestionaryAccess: checkQuestionaryAccess(
          currentCompany.realm ?? '',
        ),
      }}
    >
      {isCompletedStartup ? <Outlet /> : <Loader />}
    </KeycloakContext.Provider>
  );
}
