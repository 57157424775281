import {
  QUESTIONARY_STEPS,
  UserAnswersFlow,
  currentUserState,
  getAnswersCallback,
  getUserProfileCallback,
  useBreakpoint,
  userAnswersState,
  useTrackAnalytics,
  UserAnswers,
  QuestionPage,
  getAllQuestionPageCallback,
  slugify,
  resumeQuestionary,
  isStepThreeCompleted,
  UserAnswersPage,
} from '@laborability/commons';
import {
  IconArrowRightComponent,
  IconQuestionaryStep,
  IconWarningComponent,
  IconsStyle,
  LBTButton,
  LBTChip,
  LBTImage,
  LBTLabel,
  LBTListItem,
  LBTSpacer,
  Section,
} from '@laborability/components';
import { Box, Stack } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useRecoilCallback, useRecoilValue } from 'recoil';
import { useEffect, useState } from 'react';
import AnswerReview from './AnswerReview';

export default function QuestionaryMenu() {
  const { isDesktop } = useBreakpoint();
  const user = useRecoilValue(currentUserState);
  const answers = useRecoilValue(userAnswersState);
  const [selectedFlowId, setSelectedFlowId] = useState<number>(0);
  const [stepThreeAnswers, setStepThreeAnswers] = useState<UserAnswersPage[]>(
    [],
  );
  const [stepThreeQuestions, setStepThreeQuestions] = useState<QuestionPage[]>(
    [],
  );
  const [stepThreeFetched, setStepThreeFetched] = useState(false);
  const getUserMe = useRecoilCallback(getUserProfileCallback, []);
  const getAnswers = useRecoilCallback(getAnswersCallback, []);
  const currentUser = useRecoilValue(currentUserState);
  const navigate = useNavigate();
  const getAllPage = useRecoilCallback(getAllQuestionPageCallback, []);
  // const isQuestionaryCompleted =
  //   !userLoader &&
  //   !answerLoader &&
  //   answers.flows.length === QUESTIONARY_STEPS &&
  //   !answers.flows.find(flow => flow.position !== flow.number_of_pages);

  const isQuestionaryCompleted =
    currentUser.questionary_status?.length === QUESTIONARY_STEPS &&
    currentUser.questionary_status?.every(
      questionary => questionary.completed === true,
    );

  const isStepCompleted = (step: number) => {
    return currentUser.questionary_status?.some(
      questionary => questionary.completed && questionary.step === step,
    );
  };

  const getPages = async (id: number) => {
    const res = await getAllPage({ id: id });
    const questionPage: QuestionPage[] = res?.data.items;
    const resolvedQuestionPages = await Promise.all(questionPage);
    const pages = resolvedQuestionPages.flat();
    return pages as QuestionPage[];
  };

  const getAllAnswers = async (step: number) => {
    const res = await getAnswers();
    const answers: UserAnswers = res?.data;
    return answers.flows.find(flow => flow.step === step);
  };

  useEffect(() => {
    if (!stepThreeFetched) {
      const stepThreeFlow = answers.flows.find(flow => flow.step === 3);
      if (stepThreeFlow) {
        (async () => {
          setStepThreeQuestions(await getPages(stepThreeFlow.flow_id));
          setStepThreeAnswers(stepThreeFlow.pages);
          setStepThreeFetched(true);
        })();
      }
    }
  }, [answers.flows]);

  useEffect(() => {
    getUserMe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useTrackAnalytics([
    { key: 'event', value: 'page_view' },
    { key: 'page_title', value: 'Menu - Il tuo questionario' },
    { key: 'page_location', value: '/menu/tuo-questionario/' },
  ]);

  useEffect(() => {
    getAnswers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.id]);

  const selectedFlow = answers.flows.find(
    (flow: UserAnswersFlow) => flow.flow_id === selectedFlowId,
  );
  if (selectedFlow)
    return (
      <AnswerReview
        flow={selectedFlow}
        onClose={() => {
          setSelectedFlowId(0);
          getAnswers();
          getUserMe();
        }}
      />
    );

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        flex: 1,
      }}
    >
      <Section>
        <LBTSpacer spacing={2} />
        <LBTLabel variant="delaDisplay" component="h1">
          Questionario
        </LBTLabel>
        <LBTSpacer spacing={2} />
        <LBTLabel variant="spGroteskSubtitle" component="h2">
          Puoi modificare le risposte o continuare a rispondere se non hai
          ancora finito
        </LBTLabel>
        <LBTSpacer spacing={4} />
        <Box
          component="ul"
          maxWidth="680px"
          width="100%"
          padding={0}
          margin={0}
        >
          {answers.flows
            .slice()
            .sort((a, b) => a.step - b.step)
            .map((flow: UserAnswersFlow) => (
              <LBTListItem
                datatestid={`menu_agevolazioni_${slugify(flow.flow_name)}`}
                key={flow.flow_id}
                component="a"
                title={flow.flow_name}
                onClick={() => setSelectedFlowId(flow.flow_id)}
                leftItem={<IconQuestionaryStep step={flow.step} />}
                rightItem={
                  <Stack flexDirection="row" alignItems="center">
                    {flow.step === 3
                      ? !isStepThreeCompleted(
                          stepThreeAnswers,
                          stepThreeQuestions,
                        ) && (
                          <LBTChip
                            icon={
                              <IconWarningComponent
                                style={IconsStyle.OUTLINE}
                              />
                            }
                            color="tangerine"
                            sx={{ minWidth: '50px', marginRight: '10px' }}
                          />
                        )
                      : !isStepCompleted(flow.step) && (
                          <LBTChip
                            label={`${Math.round((flow.completed_pages * 100) / flow.total_pages)}%`}
                            color="lemonCake"
                            sx={{ minWidth: '50px', marginRight: '10px' }}
                          />
                        )}
                    <IconArrowRightComponent />
                  </Stack>
                }
                sx={{ minHeight: '58px' }}
              />
            ))}
        </Box>
        <LBTSpacer spacing={4} />
        {!isQuestionaryCompleted && (
          <LBTButton
            variant="contained"
            fullWidth
            onClick={() => {
              resumeQuestionary(currentUser, getPages, getAllAnswers, navigate);
            }}
            maxWidth="504px"
            size="large"
          >
            Continua a rispondere
          </LBTButton>
        )}
        <LBTSpacer spacing={8} isFixed />
      </Section>

      {isQuestionaryCompleted && (
        <div
          style={{
            width: '100%',
            height: isDesktop ? '514px' : '360px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            backgroundImage:
              'radial-gradient(181.73% 86.53% at 51.79% 90.97%, #59A5FF 29%, rgba(255, 255, 255, 0.05) 100%)',
          }}
        >
          <LBTSpacer spacing={4} />
          <LBTLabel variant="smallCapsBold">Incredibile ma vero</LBTLabel>
          <LBTSpacer spacing={1} />
          <LBTLabel variant="spGroteskSubtitle">
            Hai risposto a tutte le domande
          </LBTLabel>
          <LBTSpacer spacing={4} />
          <LBTImage image="astronaut" />
        </div>
      )}
    </div>
  );
}
