import { useEffect, useMemo, useState } from 'react';
import { Outlet, useMatches } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import ScrollToTop from './ScrollToTop';
import {
  BackdropLoader,
  MandatoryDialog,
  QuestionaryContext,
} from '@laborability/components';
import NavMenu from './Layouts/NavMenu';
import Footer from './Layouts/Footer';
import { navMenuState, useBreakpoint, useLoading } from '@laborability/commons';

type HandlePageTitle = {
  page_title: string;
};

const Layout = () => {
  const isLoading = useLoading();
  const matches = useMatches();
  const bgC = useRecoilValue(navMenuState);
  const { isDesktop } = useBreakpoint();
  const [step, setStep] = useState<number>(0);
  const [pageId, setPageId] = useState<number>(0);
  const [isStepCompleted, setIsStepCompleted] = useState<boolean>(false);
  const [isCookiesModalOpen, setIsCookiesModalOpen] = useState<boolean>(false);

  const getCookie = (name: string) => {
    const cookies = document.cookie
      .split('; ')
      .find(row => row.startsWith(`${name}=`));

    return cookies ? cookies.split('=')[1] : null;
  };

  const setCookie = (name: string, value: string, days: number) => {
    const expirationDate = new Date();
    expirationDate.setDate(expirationDate.getDate() + days);

    document.cookie = `${name}=${value}; expires=${expirationDate.toUTCString()}; path=/`;
  };

  const pageTitle = useMemo(() => {
    const pHandle = matches.find(
      match =>
        'handle' in match &&
        match.handle &&
        (match.handle as HandlePageTitle).page_title,
    );
    return pHandle
      ? (pHandle.handle as HandlePageTitle).page_title
      : 'Laborability';
  }, [matches]);

  useEffect(() => {
    document.title = pageTitle;
  }, [pageTitle]);

  useEffect(() => {
    getCookie('cookie_policy') !== 'accepted' && setIsCookiesModalOpen(true);
  }, []);

  return (
    <QuestionaryContext.Provider
      value={{
        pageId,
        setPageId,
        step,
        setStep,
        isStepCompleted,
        setIsStepCompleted,
      }}
    >
      <MandatoryDialog
        datatestidIcon="banner_cookie_button_x"
        datatestidButton="banner_cookie_button_ok"
        open={isCookiesModalOpen}
        onClose={() => {
          setIsCookiesModalOpen(false);
          setCookie('cookie_policy', 'accepted', 180);
        }}
        title="Informativa sui cookie"
      >
        Dritto, la piattaforma di laborability dedicata al welfare pubblico,
        utilizza sul proprio sito cookie tecnici che garantiscono la normale
        navigazione e fruizione del sito, nonché cookie analitici, assimilati ai
        cookie tecnici (cfr. provvedimento dell'8 maggio 2014 e Linee guida
        cookie e altri strumenti di tracciamento del 10 giugno 2021, del Garante
        per la protezione dei dati personali), di prime e terze parti, per
        raccogliere dati statistici, in forma anonima e aggregata, sulle visite
        e l'utilizzo del sito. Consulta la cookie policy per ulteriori dettagli
        a riguardo.
      </MandatoryDialog>
      {isLoading && (
        <BackdropLoader isStepCompleted={isStepCompleted} step={step} />
      )}
      <ScrollToTop />
      <NavMenu backgroundColor={bgC} />
      <div
        style={{
          backgroundColor: bgC,
          minHeight: `calc(100vh - ${isDesktop ? 100 : 64}px)`,
          marginTop: isDesktop ? '100px' : '64px',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Outlet />
      </div>
      <Footer />
    </QuestionaryContext.Provider>
  );
};

export default Layout;
