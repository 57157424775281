import {
  COLORS,
  FONTS,
  IconArrowRightComponent,
  IconCrossComponent,
  IconEnteComponent,
  IconsSize,
  IconsStyle,
  LBTAccordion,
  LBTCard,
  LBTDivider,
  LBTLabel,
  LBTPagination,
  CardVariant,
  LBTListItem,
  LBTSpacer,
  Section,
} from '@laborability/components';
import {
  Box,
  CardMedia,
  Grid,
  Link,
  List,
  SwipeableDrawer,
  Typography,
  TypographyProps,
} from '@mui/material';
import { useRecoilCallback, useRecoilState, useRecoilValue } from 'recoil';
import LogoAssocaaf from '../../assets/images/assocaaf.svg';
import AssocaafComunicazioneEnea from '../../components/Assocaaf/AssocaafComunicazioneEnea';
import AssocaafConsulenzaFiscale from '../../components/Assocaaf/AssocaafConsulenzaFiscale';
import AssocaafConsulenzaRistrutturazioni from '../../components/Assocaaf/AssocaafConsulenzaRistrutturazioni';
import AssocaafIMU from '../../components/Assocaaf/AssocaafIMU';
import AssocaafISEE from '../../components/Assocaaf/AssocaafISEE';
import AssocaafModello730 from '../../components/Assocaaf/AssocaafModello730';
import BoldSpan from '../../components/answerBoxes/BoldSpan';
import styled from 'styled-components';
import BonusList from '../../components/measuresPage/BonusList';
import BonusListVariantLeft from '../../components/measuresPage/BonusListVariantLeft';
import AlertsList from '../../components/measuresPage/AlertsList';
import ExternalSiteButton from '../../components/measuresPage/ExternalSiteButton';
import BonusListVariantRight from '../../components/measuresPage/BonusListVariantRight';
import {
  Misura,
  getMisuraByIdCallback,
  getMisureByCategoryIdCallback,
  useBreakpoint,
  getBenefitByIdCallback,
  measureState,
  benefitState,
  currentCompanyConfigState,
  swipeableDrawerState,
  useTrackAnalytics,
} from '@laborability/commons';
import { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { APP_ROUTES } from '../../Routes';

const StyledImageContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledImage = styled.img<{ maxwidth: string }>`
  width: 100%;
  max-width: ${props => props.maxwidth};
`;
const Image = ({ src }: { src: string }) => {
  return (
    <StyledImageContainer>
      <StyledImage src={src} maxwidth={'100%'} />
    </StyledImageContainer>
  );
};

const HowItWorksParagraph = styled(Typography)<TypographyProps>(props => ({
  '& *': {
    margin: '0',
  },
}));

const SERVICES = [
  {
    title: 'Modello 730',
    description:
      "Assocaaf prepara e invia all'Agenzia delle Entrate la tua dichiarazione dei redditi. Scrivi a dritto@assocaaf.it per farti aiutare.",
    component: (
      <Box
        sx={{
          paddingLeft: '16px',
          paddingRight: '16px',
          marginTop: '40px',
          marginBottom: '40px',
        }}
      >
        <AssocaafModello730 />
      </Box>
    ),
  },
  {
    title: 'ISEE',
    description:
      "Assocaaf richiede per te l'Indicatore della Situazione Economica Equivalente. Scrivi a dritto@assocaaf.it per farti aiutare",
    component: (
      <Box
        sx={{
          paddingLeft: '16px',
          paddingRight: '16px',
          marginTop: '40px',
          marginBottom: '40px',
        }}
      >
        <AssocaafISEE />
      </Box>
    ),
  },
  {
    title: 'IMU',
    description:
      "Assocaaf prepara gli F24 per pagare l'IMU e li invia all'Agenzia delle Entrate al posto tuo. Scrivi a dritto@assocaaf.it per farti aiutare",
    component: (
      <Box
        sx={{
          paddingLeft: '16px',
          paddingRight: '16px',
          marginTop: '40px',
          marginBottom: '40px',
        }}
      >
        <AssocaafIMU />
      </Box>
    ),
  },
  {
    title: 'Comunicazione ENEA',
    description:
      'Assocaaf invia la comunicazione necessaria per usufruire delle detrazioni legate agli interventi di risparmio energetico. Scrivi a dritto@assocaaf.it per farti aiutare',
    component: (
      <Box
        sx={{
          paddingLeft: '16px',
          paddingRight: '16px',
          marginTop: '40px',
          marginBottom: '40px',
        }}
      >
        <AssocaafComunicazioneEnea />
      </Box>
    ),
  },
  {
    title: 'Consulenza ristrutturazioni',
    description:
      "Assocaaf gestisce tutte le pratiche con l'Agenzia delle Entrate per ottenere sconto in fattura o cessione del credito. Scrivi a dritto@assocaaf.it per farti aiutare",
    component: (
      <Box
        sx={{
          paddingLeft: '16px',
          paddingRight: '16px',
          marginTop: '40px',
          marginBottom: '40px',
        }}
      >
        <AssocaafConsulenzaRistrutturazioni />
      </Box>
    ),
  },
  {
    title: 'Consulenza fiscale generica',
    description:
      'Assocaaf studia la tua situazione e ti consiglia la soluzione più conveniente. Scrivi a dritto@assocaaf.it per farti aiutare',
    component: (
      <Box
        sx={{
          paddingLeft: '16px',
          paddingRight: '16px',
          marginTop: '40px',
          marginBottom: '40px',
        }}
      >
        <AssocaafConsulenzaFiscale />
      </Box>
    ),
  },
];

const ACCORDION_ITEMS = [
  {
    question: 'A chi è rivolto',
    key: 'target_audience',
  },
  {
    question: 'Requisiti per accedere',
    key: 'access_requirements',
  },
  {
    question: 'Valore del bonus',
    key: 'bonus_value',
  },
  {
    question: 'Quando fare domanda',
    key: 'application_period',
  },
  {
    question: 'Come fare domanda',
    key: 'application_process',
  },
];

const IndennitaPage = () => {
  const { id } = useParams();
  const { isDesktop } = useBreakpoint();
  const { assocaf } = useRecoilValue(currentCompanyConfigState);
  const navigate = useNavigate();
  const misura = useRecoilValue(measureState);
  const beneficio = useRecoilValue(benefitState);

  const fetchMisure = useRecoilCallback(getMisureByCategoryIdCallback, []);
  const fetchMisuraById = useRecoilCallback(getMisuraByIdCallback, []);
  const fetchBenefitTypeById = useRecoilCallback(getBenefitByIdCallback, []);

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [misure, setMisure] = useState<Misura[]>([]);

  const [
    { open, title, component: componentDrawer, description },
    setSwipeableDrawerState,
  ] = useRecoilState(swipeableDrawerState);

  const toggleDrawer = (newOpen: boolean) => () => {
    setSwipeableDrawerState(prev => ({
      ...prev,
      open: newOpen,
      title: 'Modello 730',
      description:
        "Assocaaf prepara e invia all'Agenzia delle Entrate la tua dichiarazione dei redditi",
      component: (
        <IconEnteComponent
          size={IconsSize.LARGE}
          style={IconsStyle.FILLED}
          color={COLORS.getInstance().BW_GREYS_JET_BLACK}
        />
      ),
    }));
  };

  const renderItem = (item: Misura) => {
    return (
      <Grid item mobile={12} desktop={6} display="flex">
        <LBTCard
          onClick={() => navigate(`/${APP_ROUTES.MEASURE_DETAIL}/${item.id}`)}
          title={item.name}
          description={item.description}
          tag={item.categories
            ?.find(category =>
              item.categories!.length === 2
                ? !!category.parent_id
                : category.parent_id === null,
            )
            ?.name?.toLowerCase()}
          news={item.news ?? false}
          expired={item.expired ?? false}
          expiringSoon={item.expiring_soon ?? false}
          cardVariant={CardVariant.WHITE}
          upcoming={item.upcoming ?? false}
        />
      </Grid>
    );
  };

  const getMisure = async () => {
    if (!misura?.categories_ids?.length) return;
    const res = await fetchMisure({
      id: misura.categories_ids?.[0],
      limit: 5,
      published: true,
    });

    setMisure(
      res?.data.items
        .filter((item: Misura) => item.id !== Number(id))
        .slice(0, 4),
    );
  };

  useEffect(() => {
    fetchMisuraById({ id: Number(id) });
  }, [id]);

  useEffect(() => {
    if (misura.benefit_type_id)
      fetchBenefitTypeById({ id: misura.benefit_type_id });
    getMisure();
  }, [misura]);

  useTrackAnalytics(
    misura.id?.toString() === window.location.href.split('/').pop()
      ? [
          { key: 'event', value: 'page_view' },
          { key: 'page_title', value: `Misura ${misura.name}` },
          {
            key: 'page_location',
            value: `/app/misura/${misura.id?.toString()}`,
          },
        ]
      : [],
  );

  const anyAlertSelected: boolean | undefined =
    misura?.for_all_incomes || misura?.question_online_or_phone;

  return (
    <>
      <Section backgroundColor={COLORS.getInstance().PRIMARY_SUPERLIGHT}>
        <LBTSpacer spacing={2} />
        <LBTLabel variant="delaDisplay" component="h1">
          {misura?.name}
        </LBTLabel>
        <LBTSpacer spacing={2} />
        <LBTLabel variant="spGroteskSubtitle" component="h2">
          {misura?.description}
        </LBTLabel>
        <LBTSpacer spacing={2} />
        <Grid
          container
          gridAutoRows="1fr"
          spacing={isDesktop ? '24px' : '16px'}
          justifyContent="center"
        >
          {!isDesktop ? (
            <>
              {!!misura?.image_path || !!misura?.video_path ? (
                <Grid
                  item
                  display="flex"
                  width={isDesktop ? '417px' : '100%'}
                  height={isDesktop ? '645px' : '554px'}
                >
                  {!!misura.image_path ? (
                    <Box
                      component="img"
                      src={misura?.image_path}
                      width="100%"
                    />
                  ) : (
                    <Box width="100%" height="100%">
                      <CardMedia
                        sx={{ border: 'none', height: '100%' }}
                        component="iframe"
                        image={misura.video_path}
                        title="LBT-Video"
                      />
                    </Box>
                  )}
                </Grid>
              ) : null}
              <Grid
                item
                direction="column"
                display="flex"
                justifyContent="space-between"
                width={isDesktop ? '417px' : '100%'}
              >
                <Box>
                  <AlertsList misura={misura} isDesktop={isDesktop} />
                </Box>
                <Box>
                  <BonusList
                    misura={misura}
                    beneficio={beneficio}
                    isDesktop={isDesktop}
                  />
                  <LBTSpacer spacing={2} isFixed />
                  <ExternalSiteButton misura={misura} position="center" />
                </Box>
              </Grid>
            </>
          ) : (
            <>
              {misura?.image_path === null && misura.video_path === null ? (
                anyAlertSelected ? (
                  <>
                    <Grid
                      item
                      display="flex"
                      flexDirection="column"
                      justifyContent="space-between"
                      width={isDesktop ? '417px' : '100%'}
                    >
                      <AlertsList misura={misura} isDesktop={isDesktop} />
                      <LBTSpacer spacing={4} isFixed />
                      <ExternalSiteButton
                        misura={misura}
                        position="flex-start"
                      />
                    </Grid>
                    <Grid
                      item
                      display="flex"
                      direction="column"
                      width={isDesktop ? '417px' : '100%'}
                    >
                      <BonusList
                        misura={misura}
                        beneficio={beneficio}
                        isDesktop={isDesktop}
                      />
                    </Grid>
                  </>
                ) : (
                  <>
                    <Grid item width={isDesktop ? '417px' : '100%'}>
                      <BonusListVariantLeft
                        misura={misura}
                        beneficio={beneficio}
                        isDesktop={isDesktop}
                      />
                    </Grid>
                    <Grid
                      item
                      display="flow"
                      direction="column-reverse"
                      justifyContent="space-between"
                      width={isDesktop ? '417px' : '100%'}
                    >
                      <BonusListVariantRight
                        misura={misura}
                        isDesktop={isDesktop}
                      />
                      <LBTSpacer spacing={4} isFixed />
                      <ExternalSiteButton
                        misura={misura}
                        position="flex-start"
                      />
                    </Grid>
                  </>
                )
              ) : (
                <>
                  <Grid
                    item
                    display="flex"
                    width={isDesktop ? '417px' : '100%'}
                    height={isDesktop ? '645px' : '554px'}
                  >
                    {!!misura.image_path ? (
                      <Box
                        component="img"
                        src={misura?.image_path}
                        width="100%"
                      />
                    ) : (
                      <Box width="100%" height="100%">
                        <CardMedia
                          sx={{ border: 'none', height: '100%' }}
                          component="iframe"
                          image={misura.video_path}
                          title="LBT-Video"
                        />
                      </Box>
                    )}
                  </Grid>
                  <Grid
                    item
                    direction="column"
                    display="flex"
                    justifyContent="space-between"
                    width={isDesktop ? '417px' : '100%'}
                  >
                    <Box>
                      <BonusList
                        misura={misura}
                        beneficio={beneficio}
                        isDesktop={isDesktop}
                      />
                      <LBTSpacer spacing={2} />
                      <ExternalSiteButton misura={misura} />
                    </Box>
                    <Box>
                      <AlertsList misura={misura} isDesktop={isDesktop} />
                    </Box>
                  </Grid>
                </>
              )}
            </>
          )}
        </Grid>
        <LBTSpacer spacing={4} isFixed />
      </Section>
      {misura.how_it_works && (
        <Section backgroundColor={COLORS.getInstance().WHITE}>
          <LBTSpacer spacing={4} />
          <LBTLabel variant="delaDisplay" component="h3">
            Come funziona?
          </LBTLabel>
          <LBTSpacer spacing={4} />
          <HowItWorksParagraph
            maxWidth={'680px'}
            textAlign="left"
            fontSize="16px"
            lineHeight="normal"
            width={'100%'}
            fontFamily={FONTS.SOURCE_SANS_PRO}
            dangerouslySetInnerHTML={{ __html: misura.how_it_works as string }}
          />

          <LBTSpacer spacing={4} />
          {misura &&
            ACCORDION_ITEMS.map((item, index) => {
              const span = document.createElement('span');
              span.innerHTML = misura[item.key as keyof Misura] as string;
              if (
                span.textContent?.trim() !== '' ||
                span.innerText.trim() !== ''
              ) {
                return (
                  misura[item.key as keyof Misura] !== null && (
                    <LBTAccordion
                      sx={{ width: '100%' }}
                      uniqueKey={`${index}`}
                      key={index}
                      summary={item.question}
                      details={null}
                      dangerouslySetInnerHTML={{
                        __html: misura[item.key as keyof Misura] as string,
                      }}
                    />
                  )
                );
              } else {
                return <></>;
              }
            })}
          <LBTSpacer spacing={4} />
        </Section>
      )}
      {assocaf && (
        <Section backgroundColor={COLORS.getInstance().PRIMARY_SUPERLIGHT}>
          <LBTSpacer spacing={4} />
          <LBTLabel variant="delaDisplay" component="h3">
            Ti serve aiuto per fare domanda?
          </LBTLabel>
          <LBTSpacer spacing={4} />
          <LBTLabel variant="spGroteskSubtitle">
            Il nostro partner Assocaaf ti aiuta con le pratiche: scrivi a
            dritto@assocaaf.it per ricevere supporto
          </LBTLabel>
          <LBTSpacer spacing={4} />
          <Box maxWidth="164px" maxHeight="50px">
            <Image src={LogoAssocaaf} />
          </Box>
          <LBTSpacer spacing={2} />
          <LBTLabel maxWidth={'680px'} variant="bodyText">
            Assocaaf Spa è il centro di assistenza fiscale costituito dalle
            associazioni territoriali e di categoria di Confindustria e da oltre
            mille imprese nazionali e multinazionali
          </LBTLabel>
          <LBTSpacer spacing={6} />
          <List disablePadding sx={{ maxWidth: '680px' }}>
            <LBTLabel variant="sourceSubtitle" component="h5" content="h5">
              Servizi disponibili
            </LBTLabel>
            <LBTSpacer spacing={2} />
            <LBTDivider orientation="horizontal" width={'100%'} />
            {SERVICES.map((item, index) => (
              <>
                <LBTListItem
                  key={index}
                  onClick={() => {
                    setSwipeableDrawerState(prev => ({
                      ...prev,
                      open: true,
                      title: item.title,
                      description: item.description,
                      component: item.component,
                    }));
                  }}
                  title={item.title}
                  description={item.description}
                  rightItem={
                    <IconArrowRightComponent
                      size={IconsSize.MEDIUM}
                      style={IconsStyle.OUTLINE}
                      color={COLORS.getInstance().BLACK}
                    />
                  }
                />
                <LBTDivider orientation="horizontal" width={'100%'} />
              </>
            ))}
          </List>
          <LBTSpacer spacing={4} />
          <LBTLabel variant="bodyText">
            Per farti aiutare, scrivi a{' '}
            <Link
              href="mailto:dritto@assocaaf.it"
              color={COLORS.getInstance().BW_GREYS_JET_BLACK}
            >
              <BoldSpan>dritto@assocaaf.it</BoldSpan>
            </Link>
          </LBTLabel>
          <LBTSpacer spacing={4} />
        </Section>
      )}

      <Section backgroundColor={COLORS.getInstance().PRIMARY_IPERLIGHT}>
        <LBTSpacer spacing={4} />
        <LBTLabel variant="delaDisplay" component="h3">
          Ti potrebbero interessare
        </LBTLabel>
        <LBTSpacer spacing={2} />
        <LBTLabel variant="spGroteskSubtitle">
          Esplora bonus affini a quello che hai appena consultato
        </LBTLabel>
        <LBTSpacer spacing={4} />
        <Grid
          container
          spacing={isDesktop ? '24px' : '16px'}
          sx={{
            maxWidth: isDesktop ? '1032px' : undefined,
          }}
        >
          {isDesktop
            ? misure.map(item => renderItem(item))
            : misure.length && renderItem(misure[currentPage - 1])}
        </Grid>
        {!isDesktop && (
          <LBTPagination
            numberOfItems={misure.length}
            onPageChange={tmpPage => setCurrentPage(tmpPage)}
            currentPage={currentPage}
          />
        )}
        <LBTSpacer spacing={6} />
      </Section>
      <SwipeableDrawer
        container={undefined}
        anchor="bottom"
        open={open}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
        disableSwipeToOpen={false}
        ModalProps={{
          keepMounted: true,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: '24px',
          }}
        >
          <LBTLabel variant="miscTitleSource">{title}</LBTLabel>
          <Box
            sx={{
              position: 'absolute',
              right: '0px',
              marginRight: '16px',
              padding: '10px',
            }}
            onClick={() => {
              setSwipeableDrawerState(prev => ({
                ...prev,
                open: false,
              }));
            }}
          >
            <IconCrossComponent
              size={IconsSize.MEDIUM}
              style={IconsStyle.FILLED}
              color={COLORS.getInstance().BW_GREYS_JET_BLACK}
            ></IconCrossComponent>
          </Box>
        </Box>
        <LBTSpacer spacing={2} isFixed />
        <LBTLabel variant="miscDescription" alignSelf="center" width="100%">
          {description}
        </LBTLabel>
        <Box
          sx={{
            overflow: 'auto',
          }}
        >
          {componentDrawer}
        </Box>
      </SwipeableDrawer>
    </>
  );
};

export default IndennitaPage;
