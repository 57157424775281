import { createHashRouter, Navigate, RouterProvider } from 'react-router-dom';
import Layout from './components/Layout';
import {
  currentCompanyConfigState,
  checkQuestionaryAccess,
} from '@laborability/commons';
import { useRecoilValue } from 'recoil';
import {
  CategoryPage,
  CookiePolicy,
  Fifth,
  Fifth01,
  Fifth02,
  Fifth03,
  Fifth04,
  Fifth05,
  Fifth06,
  Home,
  MeasureDetailPage,
  NoPage404,
  PrivacyPolicy,
  SearchMisure,
  SeverancePay,
  TagPage,
  UserMenu,
  IssueReport,
  RatingPage,
  MeasureReport,
  QuestionaryIndex,
  QuestionaryMenu,
  UserAccount,
  MyBonus,
  SpecialCases,
  Logout,
  InformationArt13,
} from './pages';
import Auth from './components/Auth';

export const APP_ROUTES = {
  HOME: 'home',
  MENU: 'menu',
  USER_ACCOUNT: 'user-account',
  QUESTIONARY_MENU: 'questionary-menu',
  FIFTH: 'fifth',
  FIFTH_01: 'fifth_01',
  FIFTH_02: 'fifth_02',
  FIFTH_03: 'fifth_03',
  FIFTH_04: 'fifth_04',
  FIFTH_05: 'fifth_05',
  FIFTH_06: 'fifth_06',
  SEVERANCE_PAY: 'severance-pay',
  CATEGORY: 'category',
  MEASURE_DETAIL: 'measure-detail',
  TAG: 'tag',
  SEARCH_MISURE: 'search-misure',
  COOKIE_POLICY: 'cookie-policy',
  PRIVACY_POLICY: 'privacy-policy',
  QUESTIONARY: 'questionary',
  RATE_SERVICE: 'rate-service',
  REPORT_ISSUE: 'report-issue',
  REPORT_MEASURE: 'report-measure',
  MY_CONCESSIONS: 'my-concessions',
  MY_BONUS: 'my-bonus',
  SPECIAL_CASES: 'special-cases',
  QUESTIONARY_LOADER: 'questionary-loader',
  QUESTIONARY_COMPLETED_LOADER: 'questionary-completed-loader',
  LOGOUT: 'logout',
  ART_13: 'informativa-ex-art-13-gdpr',
};

export default function AppRoutes() {
  const { fifth_assignment, tfr, realm } = useRecoilValue(
    currentCompanyConfigState,
  );
  const hasQuestionaryAccess = checkQuestionaryAccess(realm ?? '');
  const fifthAssignmentRoutes = [
    {
      path: APP_ROUTES.FIFTH,
      element: <Fifth />,
      handle: {
        page_title: 'Cessione del quinto',
      },
    },
    {
      path: APP_ROUTES.FIFTH_01,
      element: <Fifth01 />,
      handle: {
        page_title: 'Cessione del quinto',
      },
    },
    {
      path: APP_ROUTES.FIFTH_02,
      element: <Fifth02 />,
      handle: {
        page_title: 'Cessione del quinto',
      },
    },
    {
      path: APP_ROUTES.FIFTH_03,
      element: <Fifth03 />,
      handle: {
        page_title: 'Cessione del quinto',
      },
    },
    {
      path: APP_ROUTES.FIFTH_04,
      element: <Fifth04 />,
      handle: {
        page_title: 'Cessione del quinto',
      },
    },
    {
      path: APP_ROUTES.FIFTH_05,
      element: <Fifth05 />,
      handle: {
        page_title: 'Cessione del quinto',
      },
    },
    {
      path: APP_ROUTES.FIFTH_06,
      element: <Fifth06 />,
      handle: {
        page_title: `Cos'è la cessione del quinto dello stipendio?`,
      },
    },
  ];
  const severancePayRoutes = [
    {
      path: APP_ROUTES.SEVERANCE_PAY,
      element: <SeverancePay />,
      handle: {
        page_title: `Severance Pay`,
      },
    },
  ];
  const pagesMileStone2 = [
    {
      path: APP_ROUTES.QUESTIONARY,
      element: <QuestionaryIndex />,
      handle: {
        page_title: `Questionario`,
      },
    },
    {
      path: APP_ROUTES.QUESTIONARY_MENU,
      element: <QuestionaryMenu />,
      handle: {
        page_title: `Questionario`,
      },
    },
    {
      path: APP_ROUTES.MENU,
      element: <UserMenu />,
      handle: {
        page_title: `Menù`,
      },
    },
    {
      path: APP_ROUTES.RATE_SERVICE,
      element: <RatingPage />,
      handle: {
        page_title: `Valuta il servizio`,
      },
    },
    {
      path: APP_ROUTES.REPORT_ISSUE,
      element: <IssueReport />,
      handle: {
        page_title: `Segnala un problema`,
      },
    },
    {
      path: APP_ROUTES.REPORT_MEASURE,
      element: <MeasureReport />,
      handle: {
        page_title: `Segnala un'agevolazione`,
      },
    },
    {
      path: APP_ROUTES.USER_ACCOUNT,
      element: <UserAccount />,
      handle: {
        page_title: `Acount`,
      },
    },
    {
      path: APP_ROUTES.MY_BONUS,
      element: <MyBonus />,
      handle: {
        page_title: `I miei bonus`,
      },
    },
  ];
  const router = createHashRouter([
    {
      path: '/',
      element: <Layout />,
      children: [
        {
          element: <Auth />,
          children: [
            {
              index: true,
              path: '/',
              element: <Navigate to={APP_ROUTES.HOME} />,
            },
            {
              index: true,
              element: <NoPage404 />,
              handle: {
                page_title: `404 - Not found`,
              },
            },
            {
              path: APP_ROUTES.HOME,
              element: <Home />,
              handle: {
                page_title: 'Homepage',
              },
            },
            {
              path: `${APP_ROUTES.CATEGORY}/:id`,
              element: <CategoryPage />,
              handle: {
                page_title: `Categoria`,
              },
            },
            {
              path: `${APP_ROUTES.MEASURE_DETAIL}/:id`,
              element: <MeasureDetailPage />,
              handle: {
                page_title: `Dettagli misura`,
              },
            },
            {
              path: `${APP_ROUTES.TAG}/:id`,
              element: <TagPage />,
              handle: {
                page_title: `Tag`,
              },
            },
            {
              path: APP_ROUTES.SEARCH_MISURE,
              element: <SearchMisure />,
              handle: {
                page_title: `Ricerca libera`,
              },
            },
            {
              path: APP_ROUTES.SPECIAL_CASES,
              element: <SpecialCases />,
              handle: {
                page_title: `Casi particolari`,
              },
            },
            {
              path: APP_ROUTES.LOGOUT,
              element: <Logout />,
            },
            ...(tfr ? severancePayRoutes : []),
            ...(fifth_assignment ? fifthAssignmentRoutes : []),
            ...(hasQuestionaryAccess ? pagesMileStone2 : []),
            {
              path: '*',
              element: <NoPage404 />,
            },
          ],
        },
        {
          path: APP_ROUTES.PRIVACY_POLICY,
          element: <PrivacyPolicy />,
          handle: {
            page_title: `Privacy Policy`,
          },
        },
        {
          path: APP_ROUTES.ART_13,
          element: <InformationArt13 />,
          handle: {
            page_title: `Informativa ex art. 13`,
          },
        },
        {
          path: APP_ROUTES.COOKIE_POLICY,
          element: <CookiePolicy />,
          handle: {
            page_title: `Cookie Policy`,
          },
        },
        {
          path: '*',
          element: <NoPage404 />,
        },
      ],
    },
    {
      path: '/*',
      element: <NoPage404 />,
    },
  ]);

  return <RouterProvider router={router} />;
}
